import getConfig from 'next/config'
import { UrlObject } from 'url'

export const getUserImageUrl = (imageName: string) =>
  imageName && `${getConfig().publicRuntimeConfig.CDN_URL}/user-image/${imageName}`

export const getLocalImageUrl = (imageName: string) => imageName && `/static/images/${imageName}`

export const getLocalIconUrl = (imageName: string) => imageName && `/static/icons/${imageName}`

export const getUrlObject = (href: UrlObject | string, external = false): UrlObject => {
  const attributes = external ? { rel: 'noopener noreferrer', target: '_blank' } : {}

  return typeof href === 'string' ? { href, ...attributes } : href
}
