import React, { Children, ComponentProps, ReactNode } from 'react'
import classnames from 'classnames'
import NextLink, { LinkProps } from 'next/link'
import { UrlObject } from 'url'
import { makeStyles } from '@material-ui/core/styles'
import { Omit } from '@material-ui/core'
import Typography from '@base/Typography'
import { getUrlObject } from '@helpers/urls'

const useStyles = makeStyles(() => ({
  root: {
    textDecoration: 'none',
    color: 'inherit',
  },
  underline: {
    textDecoration: 'underline',
  },
  string: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  fullWidth: {
    width: '100%',
  },
}))

export type Props = Omit<ComponentProps<typeof Typography>, 'children'> &
  Omit<LinkProps, 'href' | 'children'> & {
    className?: string
    children?: ReactNode | string
    href?: string | UrlObject
    underline?: boolean
    fullWidth?: boolean
    external?: boolean // Don't prefetch external links
    hrefAs?: { href: string | UrlObject; as: string | UrlObject }
  }

const Link = ({ className, children, href, as, hrefAs, underline, fullWidth, external, onClick, ...props }: Props) => {
  const classes = useStyles({ underline })
  const kids = Children.toArray(children)

  const link = (
    <a
      className={classnames(classes.root, classes[typeof kids[0]], className, {
        [classes.underline]: underline,
        [classes.fullWidth]: fullWidth,
      })}
      {...getUrlObject(href, external)}
      onClick={onClick}
      role='link'
      data-testid={props['data-testid']}
    >
      {typeof kids[0] === 'string' ? (
        <Typography component='span' variant='inherit' {...props} data-testid={`${props['data-testid']}-span`}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </a>
  )

  if (external) return link

  return <NextLink {...(hrefAs || { href, as })}>{link}</NextLink>
}

export default Link
